import React, { useState } from 'react';
import { X } from 'lucide-react';
import Alert from 'react-bootstrap/Alert';

const TrialBanner = ({ trialEndsAt }) => {
  const [dismissed, setDismissed] = useState(false);
  
  if (dismissed) return null;
  
  const daysRemaining = Math.ceil((new Date(trialEndsAt) - new Date()) / (1000 * 60 * 60 * 24));
  
  return (
    <Alert variant="dark" className="mb-0 py-2" style={{ 
      fontSize: '0.85rem', 
      borderRadius: 0,
      backgroundColor: '#000000',
      color: '#ffffff',
      position: 'relative',
      border: 0
    }}>
      <div style={{ textAlign: 'center', paddingRight: '20px' }}>
        <div className="d-block d-md-none">
          {daysRemaining} days left in <a href="https://www.iptalons.com/theredbook" target="_blank" rel="noopener noreferrer" style={{color: '#ffffff'}}>RedBook</a> trial
        </div>
        <div className="d-none d-md-block">
          You're currently on a free <a href="https://www.iptalons.com/theredbook" target="_blank" rel="noopener noreferrer" style={{color: '#ffffff'}}>RedBook</a> trial. {daysRemaining} days remaining.
        </div>
      </div>
      <div style={{ position: 'absolute', right: '15px', top: '50%', transform: 'translateY(-50%)' }}>
        <X
          size={14}
          color="#ffffff"
          onClick={() => setDismissed(true)}
          style={{ cursor: 'pointer' }}
        />
      </div>
    </Alert>
  );
};

export default TrialBanner;