import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Card,
  Button,
  Form,
  Table,
  Pagination,
  InputGroup,
  Toast,
  Nav,
  Badge
} from "react-bootstrap";
import supabase from "../services/supabase";

const API_URL = "https://dashport-admin-api.lookermetrics.com";

export const AdminInterface = () => {
  const [users, setUsers] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [editingUser, setEditingUser] = useState(null);
  const [newUser, setNewUser] = useState({
    email: "",
    university_token: "",
    university_name: "",
    variant: "Essentials",
    accountType: "trial",
    trialDuration: "30",
    trialEndsAt: "",
    custom_links: [{ name: "", url: "" }],
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [showPasswordToast, setShowPasswordToast] = useState(false);
  const [generatedPassword, setGeneratedPassword] = useState("");
  const toastRef = useRef(null);
  const [activeTab, setActiveTab] = useState("users");
  const [universities, setUniversities] = useState([]);
  const [newUniversity, setNewUniversity] = useState({
    name: "",
    scholarUrl: "",
    yearsBack: "5",
  });

  const calculateTrialEnd = (duration) => {
    const endDate = new Date();
    endDate.setDate(endDate.getDate() + parseInt(duration));
    return endDate.toISOString().split('T')[0];
  };

  const fetchUsers = useCallback(async () => {
    const { data, error } = await supabase
      .from("iptalons_parameters")
      .select("*");
    if (error) {
      console.error("Error fetching users:", error);
      setError("Failed to fetch users");
    } else {
      setUsers(
        data.map((user) => ({
          ...user,
          university_token: user.parameter,
          accountType: user.account_type || 'paid',
          trialEndsAt: user.trial_ends_at || '',
          trialDuration: user.trial_duration || '',
          custom_links: Array.isArray(user.custom_links)
            ? user.custom_links
            : JSON.parse(user.custom_links || "[]"),
        }))
      );
    }
  }, []);

  const fetchUniversities = useCallback(async () => {
    try {
      const accessToken = getAccessToken();
      if (!accessToken) throw new Error("No access token found");

      const response = await fetch('https://dashport-integrations-api.lookermetrics.com/extractions', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      });

      if (!response.ok) throw new Error("Failed to fetch universities");

      const data = await response.json();
      setUniversities(data);
    } catch (error) {
      console.error("Error fetching universities:", error);
      setError("Failed to fetch universities");
    }
  }, []);

  useEffect(() => {
    fetchUsers();
    fetchUniversities();
  }, [fetchUsers, fetchUniversities]);

  const getAccessToken = () => {
    const tokenKey = Object.keys(localStorage).find((key) =>
      key.endsWith("-auth-token")
    );
    if (tokenKey) {
      const tokenData = JSON.parse(localStorage.getItem(tokenKey));
      return tokenData.access_token;
    }
    return null;
  };

  // Filter users based on search term
  const filteredUsers = users.filter((user) =>
    user.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Calculate pagination
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleInputChange = (e, index, field) => {
    if (field === "trialDuration") {
      const duration = e.target.value;
      setNewUser({
        ...newUser,
        trialDuration: duration,
        trialEndsAt: calculateTrialEnd(duration)
      });
    } else if (field === "university") {
      const selectedUniversity = universities.find(uni => uni.university_token === e.target.value);
      setNewUser({ 
        ...newUser, 
        university_token: e.target.value,
        university_name: selectedUniversity ? selectedUniversity.university_name : ""
      });
    } else if (field === "custom_links") {
      const updatedLinks = [...newUser.custom_links];
      updatedLinks[index] = {
        ...updatedLinks[index],
        [e.target.name]: e.target.value,
      };
      setNewUser({ ...newUser, custom_links: updatedLinks });
    } else {
      setNewUser({ ...newUser, [e.target.name]: e.target.value });
    }
  };

  const addField = (field) => {
    if (field === "custom_links") {
      setNewUser({
        ...newUser,
        custom_links: [...newUser.custom_links, { name: "", url: "" }],
      });
    }
  };

  const removeField = (index, field) => {
    if (field === "custom_links") {
      const updatedLinks = newUser.custom_links.filter((_, i) => i !== index);
      setNewUser({ ...newUser, custom_links: updatedLinks });
    }
  };

  const generatePassword = () => {
    const chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const password = Array.from(
      { length: 15 },
      () => chars[Math.floor(Math.random() * chars.length)]
    ).join("");
    setGeneratedPassword(password);
    return password;
  };

  const validateForm = () => {
    if (!newUser.email || !newUser.university_token) {
      setError("Please fill in all required fields.");
      return false;
    }
    if (
      newUser.custom_links.some((link) => !link.name.trim() || !link.url.trim())
    ) {
      setError(
        "Please fill in both name and URL for all custom links or remove empty ones."
      );
      return false;
    }
    return true;
  };

  const createOrUpdateUser = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setLoading(true);
    setError(null);

    const password = editingUser ? undefined : generatePassword();
    const userDataToSave = {
      email: newUser.email,
      password: password,
      userData: {
        university_token: newUser.university_token,
        university_name: newUser.university_name,
        variant: newUser.variant,
        account_type: newUser.accountType,
        trial_duration: newUser.trialDuration,
        trial_ends_at: newUser.trialEndsAt,
        custom_links: newUser.custom_links,
      },
    };

    try {
      const accessToken = getAccessToken();
      if (!accessToken) throw new Error("No access token found");

      const url = editingUser
        ? `${API_URL}/users/${editingUser.email}`
        : `${API_URL}/users`;
      const method = editingUser ? "PUT" : "POST";

      const response = await fetch(url, {
        method: method,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(userDataToSave),
      });

      if (!response.ok) throw new Error("Failed to create/update user");

      setNewUser({
        email: "",
        university_token: "",
        university_name: "",
        variant: "Essentials",
        accountType: "trial",
        trialDuration: "30",
        trialEndsAt: "",
        custom_links: [{ name: "", url: "" }],
      });
      setEditingUser(null);
      setShowForm(false);
      fetchUsers();

      if (!editingUser) {
        setShowPasswordToast(true);
      }
    } catch (error) {
      console.error("Error creating/updating user:", error);
      setError("Failed to create/update user");
    } finally {
      setLoading(false);
    }
  };

  const deleteUser = async (email) => {
    if (window.confirm("Are you sure you want to delete this user?")) {
      try {
        const accessToken = getAccessToken();
        if (!accessToken) throw new Error("No access token found");

        const response = await fetch(`${API_URL}/users/${email}`, {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        if (!response.ok) throw new Error("Failed to delete user");
        fetchUsers();
      } catch (error) {
        console.error("Error deleting user:", error);
        setError("Failed to delete user");
      }
    }
  };

  const copyPasswordToClipboard = () => {
    navigator.clipboard.writeText(generatedPassword).then(
      () => {},
      (err) => {
        console.error("Could not copy password: ", err);
      }
    );
  };

  const startEditing = (user) => {
    setEditingUser(user);
    setNewUser({
      email: user.email,
      university_token: user.parameter,
      university_name: user.university_name,
      variant: user.variant || "Essentials",
      accountType: user.accountType || "trial",
      trialDuration: user.trialDuration || "30",
      trialEndsAt: user.trialEndsAt || "",
      custom_links: Array.isArray(user.custom_links)
        ? user.custom_links
        : JSON.parse(user.custom_links || "[]"),
    });
    setShowForm(true);
  };

  const renderUserManagement = () => (
    <>
      {!showForm ? (
        <>
          <div className='d-flex justify-content-between align-items-center mb-3'>
            <InputGroup className='w-50'>
              <InputGroup.Text>
                <i className='bi bi-search' style={{ fontSize: "18px", fontWeight: "bold" }}></i>
              </InputGroup.Text>
              <Form.Control
                type='text'
                placeholder='Search by email'
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </InputGroup>
            <Button onClick={() => setShowForm(true)}>
              <i className='bi bi-plus-circle' style={{ fontSize: "18px", fontWeight: "bold", marginRight: "6px" }}></i>
              Add New User
            </Button>
          </div>
          <div className='table-responsive'>
            <Table className='mt-4'>
              <thead>
                <tr>
                  <th>Email</th>
                  <th>University</th>
                  <th>Variant</th>
                  <th>Type</th>
                  <th>Status</th>
                  <th>Expiration</th>
                  <th>Custom Links</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {currentUsers.map((user) => (
                  <tr key={user.email}>
                    <td>{user.email}</td>
                    <td>{user.university_name || '-'}</td>
                    <td>{user.variant || 'Essentials'}</td>
                    <td>
                      <Badge bg={user.accountType === 'trial' ? 'warning' : 'success'}>
                        {user.accountType === 'trial' ? 'Trial' : 'Paid'}
                      </Badge>
                    </td>
                    <td>
                      {user.accountType === 'trial' && (
                        <Badge bg={new Date(user.trialEndsAt) > new Date() ? 'success' : 'danger'}>
                          {new Date(user.trialEndsAt) > new Date() ? 'Active' : 'Expired'}
                        </Badge>
                      )}
                    </td>
                    <td>
                      {user.accountType === 'trial' ? (
                        <div className="d-flex align-items-center">
                          {new Date(user.trialEndsAt).toLocaleDateString()}
                        </div>
                      ) : '-'}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {user.custom_links.length > 0 ? user.custom_links.length : "-"}
                    </td>
                    <td>
                      <div style={{ display: "flex", justifyContent: "start" }}>
                        <Button
                          variant="outline-primary"
                          size="sm"
                          onClick={() => startEditing(user)}
                          className="me-2"
                        >
                          <i className='bi bi-pencil' style={{ fontSize: "14px", fontWeight: "bold" }}></i>
                        </Button>
                        <Button
                          variant="outline-danger"
                          size="sm"
                          onClick={() => deleteUser(user.email)}
                        >
                          <i className='bi bi-trash' style={{ fontSize: "14px", fontWeight: "bold" }}></i>
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
                {Array.from({
                  length: Math.max(0, usersPerPage - currentUsers.length),
                }).map((_, index) => (
                  <tr key={`empty-${index}`} style={{ height: "48px" }}>
                    <td colSpan='8'>&nbsp;</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          <div className='d-flex justify-content-between align-items-center mt-2'>
            <div style={{ fontSize: "small", marginLeft: "4px" }}>
              {`${users.length} / 10 users`}
            </div>
            <Pagination className='mt-3 justify-content-end'>
              <Pagination.Prev
                disabled={currentPage === 1}
                onClick={() => currentPage > 1 && paginate(currentPage - 1)}
              />
              {[...Array(Math.ceil(users.length / usersPerPage)).keys()].map(
                (number) => (
                  <Pagination.Item
                    key={number + 1}
                    active={number + 1 === currentPage}
                    onClick={() => paginate(number + 1)}
                  >
                    {number + 1}
                  </Pagination.Item>
                )
              )}
              <Pagination.Next
                disabled={currentPage === Math.ceil(users.length / usersPerPage)}
                onClick={() =>
                  currentPage < Math.ceil(users.length / usersPerPage) &&
                  paginate(currentPage + 1)
                }
              />
            </Pagination>
          </div>
        </>
      ) : (
        <Form onSubmit={createOrUpdateUser}>
          <Form.Group className='mb-3'>
            <Form.Label>Email</Form.Label>
            <Form.Control
              type='email'
              name='email'
              value={newUser.email}
              onChange={(e) => handleInputChange(e)}
              required
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Account Type</Form.Label>
            <Form.Select
              name="accountType"
              value={newUser.accountType}
              onChange={(e) => handleInputChange(e)}
              required
            >
              <option value="trial">Trial</option>
              <option value="paid">Paid Subscription</option>
            </Form.Select>
          </Form.Group>

          {newUser.accountType === 'trial' && (
            <Form.Group className="mb-3">
              <Form.Label>Trial Duration (days)</Form.Label>
              <Form.Select
                name="trialDuration"
                value={newUser.trialDuration}
                onChange={(e) => handleInputChange(e, null, "trialDuration")}
                required
              >
                <option value="7">7 days</option>
                <option value="14">14 days</option>
                <option value="30">30 days</option>
                <option value="60">60 days</option>
                <option value="90">90 days</option>
              </Form.Select>
              {newUser.trialEndsAt && (
                <Form.Text className="text-muted">
                  Trial will expire on {new Date(newUser.trialEndsAt).toLocaleDateString()}
                </Form.Text>
              )}
            </Form.Group>
          )}

          <Form.Group className='mb-3'>
            <Form.Label>University</Form.Label>
            <Form.Select
              name='university'
              value={newUser.university_token}
              onChange={(e) => handleInputChange(e, null, 'university')}
              required
            >
              <option value="" disabled={!!newUser.university_token}>
                {newUser.university_token ? newUser.university_name : "Select a university"}
              </option>
              {universities.map((uni) => (
                <option key={uni.university_token} value={uni.university_token}>
                  {uni.university_name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label>Dashboard Variant</Form.Label>
            <Form.Select
              name='variant'
              value={newUser.variant}
              onChange={(e) => handleInputChange(e)}
              required
            >
              <option value="Essentials">Essentials</option>
              <option value="Redbook">Redbook</option>
            </Form.Select>
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label style={{ marginRight: "8px" }}>Custom Links</Form.Label>
            {newUser.custom_links.map((link, index) => (
              <div key={index} className='d-flex mb-2'>
                <Form.Control
                  type='text'
                  name='name'
                  placeholder='Link Name'
                  value={link.name}
                  onChange={(e) => handleInputChange(e, index, "custom_links")}
                  required
                />
                <Form.Control
                  type='url'
                  name='url'
                  placeholder='URL'
                  value={link.url}
                  onChange={(e) => handleInputChange(e, index, "custom_links")}
                  required
                  className='ms-2'
                />
                <Button
                  variant='outline-danger'
                  onClick={() => removeField(index, "custom_links")}
                  className='ms-2'
                >
                  <i
                    className='bi bi-x'
                    style={{ fontSize: "16px", fontWeight: "bold" }}
                  ></i>
                </Button>
              </div>
            ))}
            <Button
              variant='outline-primary'
              onClick={() => addField("custom_links")}
            >
              <i
                className='bi bi-plus-circle'
                style={{ fontSize: "16px", fontWeight: "bold" }}
              ></i>{" "}
              Add Custom Link
            </Button>
          </Form.Group>
          <Button type='submit' disabled={loading}>
            {loading
              ? "Saving..."
              : editingUser
              ? "Update User"
              : "Create User"}
          </Button>
          <Button
            variant='secondary'
            onClick={() => {
              setShowForm(false);
              setEditingUser(null);
              setNewUser({
                email: "",
                university_token: "",
                university_name: "",
                variant: "Essentials",
                accountType: "trial",
                trialDuration: "30",
                trialEndsAt: "",
                custom_links: [{ name: "", url: "" }],
              });
            }}
            className='ms-2'
          >
            Cancel
          </Button>
        </Form>
      )}
    </>
  );

  const extractUniversityId = (url) => {
    const match = url.match(/org=(\d+)/);
    return match ? match[1] : null;
  };

  const handleUniversityInputChange = (e) => {
    const { name, value } = e.target;
    setNewUniversity({ ...newUniversity, [name]: value });
  };

  const addUniversity = async (e) => {
    e.preventDefault();
    setError(null);

    const universityId = extractUniversityId(newUniversity.scholarUrl);
    if (!universityId) {
      setError("Invalid Google Scholar URL");
      return;
    }

    const yearsBack = newUniversity.yearsBack === "all-time" ? 0 : parseInt(newUniversity.yearsBack);

    const newUniversityData = {
      university_name: newUniversity.name,
      scholar_url: newUniversity.scholarUrl,
      status: "Pending",
      researchers_count: null,
      last_sync_date: new Date().toISOString(),
      years_back: yearsBack
    };

    setUniversities([newUniversityData, ...universities]);
    setNewUniversity({ name: "", scholarUrl: "", yearsBack: "5" });

    try {
      const accessToken = getAccessToken();
      if (!accessToken) {
        throw new Error("No access token found");
      }

      const response = await fetch('https://dashport-integrations-api.lookermetrics.com/trigger-extraction', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify({
          universityName: newUniversityData.university_name,
          scholarUrl: newUniversityData.scholar_url,
          yearsBack: yearsBack
        })
      });

      if (!response.ok) {
        throw new Error("Failed to trigger extraction");
      }

      const data = await response.json();

      setUniversities(prevUniversities => 
        prevUniversities.map(uni => 
          uni.scholar_url === newUniversityData.scholar_url
            ? { ...uni, status: "Completed", researchers_count: data.researchersCount }
            : uni
        )
      );
    } catch (error) {
      console.error("Error adding university:", error);
      setUniversities(prevUniversities => 
        prevUniversities.map(uni => 
          uni.scholar_url === newUniversityData.scholar_url
            ? { ...uni, status: "Failed" }
            : uni
        )
      );
      setError("Failed to add university");
    }
  };

  const renderDataPipeline = () => {
    const truncateUrl = (url, maxLength = 30) => {
      if (url.length <= maxLength) return url;
      return url.substring(0, maxLength - 3) + "...";
    };

    const formatYearsBack = (yearsBack) => {
      if (yearsBack === 0) return "All Time";
      return yearsBack === 1 ? "1 Year" : `${yearsBack} Years`;
    };

    return (
      <div>
        <Form onSubmit={addUniversity}>
          <div className='d-flex align-items-end mb-3'>
            <Form.Group className='me-2' style={{ width: "25%" }}>
              <Form.Label>University Name</Form.Label>
              <Form.Control
                type='text'
                name="name"
                value={newUniversity.name}
                onChange={handleUniversityInputChange}
                required
              />
            </Form.Group>
            <Form.Group className='me-2' style={{ width: "40%" }}>
              <Form.Label>Google Scholar URL</Form.Label>
              <Form.Control
                type='url'
                name="scholarUrl"
                value={newUniversity.scholarUrl}
                onChange={handleUniversityInputChange}
                required
              />
            </Form.Group>
            <Form.Group className='me-2' style={{ width: "20%" }}>
              <Form.Label>Publication Timeframe</Form.Label>
              <Form.Select
                name="yearsBack"
                value={newUniversity.yearsBack}
                onChange={handleUniversityInputChange}
                required
              >
                <option value="all-time">All Time</option>
                {[...Array(10)].map((_, i) => (
                  <option key={i+1} value={i+1}>{i+1} {i === 0 ? 'Year' : 'Years'}</option>
                ))}
              </Form.Select>
            </Form.Group>
            <Button type='submit' style={{ height: "38px" }}>
              Add University
            </Button>
          </div>
        </Form>

        {error && <p className='text-danger mt-3'>{error}</p>}

        <Table className='mt-4' style={{ fontSize: "0.9rem" }}>
          <thead>
            <tr>
              <th style={{ width: "20%" }}>University Name</th>
              <th style={{ width: "25%" }}>Google Scholar URL</th>
              <th style={{ width: "15%" }}>Publication Timeframe</th>
              <th style={{ width: "15%" }}>Status</th>
              <th style={{ width: "10%" }}>Count</th>
              <th style={{ width: "15%" }}>Last Sync</th>
            </tr>
          </thead>
          <tbody>
            {universities.map((uni, index) => (
              <tr key={index}>
                <td style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}>
                  {uni.university_name}
                </td>
                <td style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}>
                  <a href={uni.scholar_url} target='_blank' rel='noopener noreferrer' title={uni.scholar_url}>
                    {truncateUrl(uni.scholar_url)}
                  </a>
                </td>
                <td>{formatYearsBack(uni.years_back)}</td>
                <td>{uni.status}</td>
                <td>{uni.researchers_count || "-"}</td>
                <td style={{ whiteSpace: "nowrap" }}>
                  {new Date(uni.last_sync_date).toLocaleDateString()} {new Date(uni.last_sync_date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                </td>
              </tr>
            ))}
            {/* Add empty rows to ensure consistent height */}
            {Array.from({ length: Math.max(0, 10 - universities.length) }).map(
              (_, index) => (
                <tr key={`empty-${index}`}>
                  <td colSpan='6'>&nbsp;</td>
                </tr>
              )
            )}
          </tbody>
        </Table>
      </div>
    );
  };

  return (
    <Card>
      <Card.Body className='p-0'>
        <Nav variant='tabs' className='border-bottom'>
          <Nav.Item>
            <Nav.Link
              active={activeTab === "users"}
              onClick={() => setActiveTab("users")}
              className={`px-4 py-2 rounded-0 ${
                activeTab === "users" ? "fw-bold" : ""
              }`}
              style={{
                border: "none",
                borderBottom:
                  activeTab === "users" ? "3px solid #0d6efd" : "none",
                backgroundColor: "white",
                color: activeTab === "users" ? "#0d6efd" : "#495057",
                marginBottom: "-1px",
              }}
            >
              User Management
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              active={activeTab === "pipeline"}
              onClick={() => setActiveTab("pipeline")}
              className={`px-4 py-2 rounded-0 ${
                activeTab === "pipeline" ? "fw-bold" : ""
              }`}
              style={{
                border: "none",
                borderBottom:
                  activeTab === "pipeline" ? "3px solid #0d6efd" : "none",
                backgroundColor: "white",
                color: activeTab === "pipeline" ? "#0d6efd" : "#495057",
                marginBottom: "-1px",
              }}
            >
              Data Pipeline
            </Nav.Link>
          </Nav.Item>
        </Nav>

        <div className='p-3'>
          {activeTab === "users"
            ? renderUserManagement()
            : renderDataPipeline()}

          {error && <p className='text-danger mt-3'>{error}</p>}
        </div>

        <Toast
          ref={toastRef}
          onClose={() => setShowPasswordToast(false)}
          show={showPasswordToast}
          delay={30000}
          autohide
          style={{
            position: "fixed",
            top: 20,
            right: 20,
            minWidth: 300,
            zIndex: 1000,
          }}
        >
          <Toast.Header>
            <strong className='me-auto'>New User's Password</strong>
          </Toast.Header>
          <Toast.Body>
            <p>Password: {generatedPassword}</p>
            <Button
              onClick={copyPasswordToClipboard}
              variant='primary'
              size='sm'
            >
              Copy Password
            </Button>
          </Toast.Body>
        </Toast>
      </Card.Body>
    </Card>
  );
};
